<template>
  <div>
    <CrudRead v-bind="this.$store.state.stone_model.page_setting"></CrudRead>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>